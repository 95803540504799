import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './RequestRecord.css';

const HealthPage = () => {
  const [requestData, setrequestData] = useState({
    branch:'',
    name: '',
    subject: '',
    detail: ''
  });

  const [responseMessage, setResponseMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Yeni state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setrequestData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true); // İşlem başladığında butonu pasif yap

      const apiUrl = 'https://api.amanosdoner.com/request-record-amanos-doner';
      const response = await axios.post(apiUrl, requestData);

      toast.success('Talebiniz Kaydedildi');

      // Kayıt tamamlandıktan sonra alanları temizle
      setrequestData({
        branch:'',
        name: '',
        subject: '',
        detail: ''
      });
    } catch (error) {
      toast.error('Hata');
    } finally {
      setIsLoading(false); // İşlem tamamlandığında butonu tekrar aktif yap
    }
  };

  return (
    <div className="health-page-container">
     <h1 className="title">Yeni Talep</h1>
      <br />      

      <Link to="/">
        <button className="back-to-home-button">Ana Sayfa</button>
      </Link>
      
      <br />
      <form onSubmit={handleSubmit} className="health-form">
      <div className="form-group">
          <label>Şube</label>
          <select
            name="branch"
            value={requestData.branch}
            style={{ height: '50px', fontSize: '15px' }} 
            onChange={handleChange}

          >
            <option value="null"></option>
            <option value="Zonguldak Merkez">Zonguldak Merkez</option>
            <option value="Zonguldak İncivez">Zonguldak İncivez</option>
            <option value="İstanbul Üsküdar">İstanbul Üsküdar</option>
          </select>
      </div>

        <div className="form-group">
          <label>İsim</label>
          <input
            type="text"
            name="name"
            value={requestData.name}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Konu</label>
          <input
            type="text"
            name="subject"
            value={requestData.subject}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Detay</label>
          <textarea
            name="detail"
            value={requestData.detail}
            onChange={handleChange}
          />
        </div>

        <button type="submit" disabled={isLoading}>Kaydet</button>
      </form>
      {isLoading && <p>Kaydediliyor...</p>}

      {responseMessage && (
        <div className="response-message">
          <p>{responseMessage}</p>
        </div>
      )}
     <ToastContainer />

    </div>
  );
};

export default HealthPage;
