// src/components/HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css'; // Import your CSS file
import './GetResult';
import './RequestRecord';
const HomePage = () => {
  return (
    <div>
      <h2>Talep Yönetim Sistemi</h2>
      {/* "Göster" butonuna tıklandığında "/get-result" rotasına yönlendirme */}
      <Link to="/RequestRecord">
        <button className="action-button">Talep Kayıt</button>
      </Link>
      <br />  <br />
      <Link to="/GetResult">
        <button className="action-button">Talepleri Listele</button>
      </Link>
      <br />
    </div>
  );
};

export default HomePage;
